import React from 'react';
import logo from './logo-no-background.png';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Nav, Navbar, NavbarBrand, NavbarToggler, Collapse, NavItem, NavLink } from 'reactstrap';
import './Header.css';

function Header () {

    const [isOpen, setIsOpen] = React.useState(false);

    return (
        <header className="header">
            <Navbar className="navbar" expand="md">
                <NavbarBrand href="/">
                    <img className="navbar-brand Logo" src={logo} alt="Brand Logo" />
                </NavbarBrand>
                <NavbarToggler onClick={() => { setIsOpen(!isOpen) }} />
                <Collapse isOpen={isOpen} navbar>
                    <Nav className="Nav">
                        <NavItem>
                            <NavLink href="/">Home</NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink href="/shop">Shop</NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink href="/latest">Latest</NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink href="/login">Login</NavLink>
                        </NavItem>
                    </Nav>
                </Collapse>
            </Navbar>
        </header>
    );
}
export default Header;