import { React, ChangeEvent, useState, useRef} from 'react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faStar, faIndianRupeeSign} from '@fortawesome/free-solid-svg-icons';
import MovingComponent from 'react-moving-text';
import 'bootstrap/dist/css/bootstrap.min.css';
import './Homepage.css';

function MarkdownPreview () {
     const letter = "Customize Sign";
     const targetRef = useRef(null);
     const [selectedStyleKey, setSelectedStyleKey] = useState('');
     const [selectedFontKey, setSelectedFontKey] = useState('');
     const [selectedSizeKey, setSelectedSizeKey] = useState('');
     const [inputText, setInputText] = useState("Spire Homes");
     const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
        // 👇 Store the input value to local state
        setInputText(e.target.value);
     }; 
    const applySelectedStyle = (styleKey) => {
    if (targetRef.current && colors[styleKey]) {
      // Apply the selected style to the fixed target element
      Object.assign(targetRef.current.style, colors[styleKey]);
    }
  };

  const applySelectedFont = (fontKey) => {
    if (targetRef.current && fonts[fontKey]) {
      // Apply the selected style to the fixed target element
      Object.assign(targetRef.current.style, fonts[fontKey]);
    }
  };

  const applySelectedSize = (sizeKey) => {
    if (targetRef.current && sizes[sizeKey]) {
      // Apply the selected style to the fixed target element
      Object.assign(targetRef.current.style, sizes[sizeKey]);
    }
  };

  const colors = {
  red: {
    color:"Red",
    backgroundColor:"Red",
    textShadow:"0 0 10px Red",
  },
  blue: {
    color:"DodgerBlue",
    backgroundColor:"DodgerBlue",
    textShadow:"0 0 10px DodgerBlue",
  },
  violet: {
    color: "Violet",
    backgroundColor:"Violet", 
    textShadow:"0 0 10px Violet",
  },
  green: {
    color: "Green",
    backgroundColor:"Green",
    textShadow:"0 0 10px Green",
  },
  orange: {
    color: "Orange",
    backgroundColor:"Orange",
    textShadow:"0 0 10px Orange",
  },
  gray: {
    color: "Gray",
    backgroundColor:"Gray",
    textShadow:"0 0 10px Gray",
  },
  tomato: {
    color: "Tomato",
    backgroundColor:"Tomato",
    textShadow:"0 0 10px Tomato",
  },
  mediumSeaGreen: {
    color:"MediumSeaGreen",
    backgroundColor:"MediumSeaGreen", 
    textShadow:"0 0 10px MediumSeaGreen",
  }, 
  };

  const fonts = {
    Style: {
   fontFamily: "Style Script",
   fontSize: "50px",

    },
    Aguafina: {
    fontFamily: "Aguafina Script",
    fontSize: "50px",
    },
    Courier: {
    fontFamily: "Courier-Serif",
    fontSize: "50px",
    fontWeight: "bold",
    },
    Cursive: {
    fontFamily: "Cursive-Serif",
    fontSize: "50px",
    fontWeight: "bold",
    },
   Verdana: {
    fontFamily: "Verdana-Serif",
    fontSize: "50px",
    fontWeight: "bold",
    },
    Didot: {
    fontFamily: "Didot-Serif",
    fontSize: "50px",
    fontWeight: "bold",
    },
    Comic: {
    fontFamily: "Comic-Serif",
    fontSize: "50px",
    fontWeight: "bold",
    },
    Jazz: {
    fontFamily: "Jazz-Serif",
    fontSize: "50px",
    fontWeight: "bold",
    },
    Optima: {
    fontFamily: "Optima-Serif",
    fontSize: "50px",
    fontWeight: "bold",
    },
    Luminari: {
    fontFamily: "Luminari-Serif",
    fontSize: "50px",
    fontWeight: "bold",
    },
    };

    const sizes = {
  Reguler:{
    fontSize: "50px",
  },
  Medium: {
    fontSize: "60px",
  },
  Large: {
    fontSize: "70px",
  },
  };

    return (
        <div className="row">
            <div className="column">
                <div className='lightBoard'>
                 <h2 ref={targetRef} style={{ color: "#FFF" }}>{inputText}</h2>
                 </div>
                <div className="left-column">
                </div>
            </div>
            <div className="column">
            <div className="right-column">
                <div>
                    <MovingComponent className="centered"
                        type="shakeMix"
                        duration="1500ms"
                        delay="index * 100ms"
                        direction="normal"
                        timing="linear"
                        iteration="3"
                        fillMode="forwards">
                        {letter}
                    </MovingComponent>
                </div>
                <div className='str'>
                    <FontAwesomeIcon icon={faStar} fontSize={20} color="blue" spin />
                    <FontAwesomeIcon icon={faStar} fontSize={20} color="blue" spin />
                    <FontAwesomeIcon icon={faStar} fontSize={20} color="blue" spin />
                    <FontAwesomeIcon icon={faStar} fontSize={20} color="blue" spin />
                    <FontAwesomeIcon icon={faStar} fontSize={20} color="blue" spin />
                </div>
                <div className="bold sh_inr">
                    <FontAwesomeIcon icon={faIndianRupeeSign} fontSize={30} color="blue" />
                </div>

                <h4 className='Ttext'>Type Your Text</h4>

             <div className='writeText'>
             <textarea className="textinput" type="text" cols="50" rows="1" onChange={handleChange} value={inputText} maxLength={10}> </textarea>
            <hr />
            </div>
            <div className= "font-btns">
            <h2> Select Your Font</h2>
        {Object.keys(fonts).map((fontKey) => (
          <button
            key={fontKey} style={fonts[fontKey]}
            onClick={() => {
              setSelectedFontKey(fontKey);
              applySelectedFont(fontKey);
            }}
          >
            {fontKey}
          </button>
        ))}
            </div>
            <div className= "color-btns">
            <h2> Choose Your Color</h2>
        {Object.keys(colors).map((styleKey) => (
          <button
            key={styleKey} style={colors[styleKey]}
            onClick={() => {
              setSelectedStyleKey(styleKey);
              applySelectedStyle(styleKey);
            }}
          >
            {styleKey}
          </button>
        ))}
            </div>

            <div className= "size-btns">
            <h2> Pick Your Size</h2>
        {Object.keys(sizes).map((sizeKey) => (
          <button
            key={sizeKey} 
            onClick={() => {
              setSelectedSizeKey(sizeKey);
              applySelectedSize(sizeKey);
            }}
          >
            {sizeKey}
          </button>
        ))}
            </div>

            </div>
        {selectedFontKey && (
        <p>Selected Font: {selectedFontKey}</p>
      )}

      {selectedStyleKey && (
        <p>Selected Color: {selectedStyleKey}</p>
      )}

      {selectedSizeKey && (
        <p>Selected Size: {selectedSizeKey}</p>
      )}
            </div>
        </div>
    );
}
export default MarkdownPreview;