import React from 'react';
import Header from './components/Pages/Header';

import "./App.css";
import MarkdownPreview from './components/Pages/MarkdownPreview';
function App () {
  return (
    <div>
      <Header />
      <MarkdownPreview />
        </div>
  );
}
export default App;